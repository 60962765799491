import Top from './Top';
import Nav from './Nav';
import Hamburger from './Hamburger';
import Main from './Main';

function Body() {
    return (
        <div>
            <Top />
            <Nav />
            <Hamburger />
            <Main />
        </div>
    );
}

export default Body;