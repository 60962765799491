import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import store from './store';
import Body from './Body';

import './App.css';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Helmet>
          <title>Prometheus Studios</title>
          <meta name="description" content="Competent and Joyous Improv Instruction in the Hudson Valley." />
          <meta name="keywords" content="improv, hudson valley, comedy, theater, acting, classes, workshops, instruction, fun, laughter, learning, community, performance, stage, theater, theatre, acting" />
          <meta property="og:title" content="Prometheus Studios" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.prometheusstudios.org/" />
          <meta property="og:image" content="http://www.prometheusstudios.org/logo2160.png" />
          <meta property="og:description" content="Competent and Joyous Improv Instruction in the Hudson Valley." />
          <link rel="apple-touch-icon" href="https://www.prometheusstudios.org/logo192.png" />
          <link rel="manifest" href="https://www.prometheusstudios.org/manifest.json" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-404TGSM614"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-404TGSM614');
            `}
          </script>
        </Helmet>
        <Routes>
          <Route path="/" element={<Body />} />
          <Route path="/:pageName" element={<Body />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;