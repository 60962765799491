import { 
    useDispatch,
    useSelector
 } from "react-redux";

import { 
    loadPageContent,
    selectOpenedPageContent
 } from "./store";

 import React, { 
    useEffect,
    useState
 } from "react";

 import { Helmet } from "react-helmet";

 import Loading from "./Loading";

function DynamicTag({ tag, children, className, link }) {
    const props = { className };
    if (link) {
        props.href = link;
        if (!link.startsWith("/")) {
            props.target = "_blank";
            props.rel = "noopener noreferrer";
        }
    }

    return React.createElement(tag, props, children);
  }

  function turnSpacesIntoDashes(string) {
    return string.replace(/\s+/g, '-').toLowerCase();
  }


function Main() {
    const dispatch = useDispatch();
    
    const { loading, error } = useSelector((state) => state.pages);
    const { isMobile } = useSelector((state) => state.device);
    const openedPageContent = useSelector(selectOpenedPageContent)?.content;
    const openedPage = useSelector(state => state.openedPage);


    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        dispatch(loadPageContent());
    }, [dispatch]);

    useEffect(() => {
        if (!loading) {
            const timer = setTimeout(() => {
                setShowLoading(false);
            }, 3000); // Minimum 3 seconds delay

            return () => clearTimeout(timer);
        }
    }, [loading]);

    if (loading || showLoading) {
        return <Loading />;
    }
    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <Helmet>
                <meta property="og:url" content={"https://www.prometheusstudios.org/" + openedPage} />
                {openedPage === "solid-foundations" ? (<meta property="og:image" content="http://www.prometheusstudios.org/media/EarlyFall2024.webp" />) : null}
            </Helmet>
        <div className="container">
                <div className="grid-container">
                    {openedPageContent && openedPageContent[0].type === "hero header" ? (
                        <header className="hero">
                            <DynamicTag tag={openedPageContent[0].tag} className={`${turnSpacesIntoDashes(openedPageContent[0].type)}`}>
                            {openedPageContent[0].text}
                            </DynamicTag>
                        </header>
                    ) : "Loading..."}
                <div></div>
            </div>  
        <main>
            <div className="grid-container">
                <div></div>
                <div>
                {openedPageContent && openedPageContent.map((item, index) => {                

                    if (item.type === "hero header") {
                        return null;
                    }
                    if ((openedPage === "classes") && isMobile &&  item.type !== "summary") {
                        return null;
                    }
                    if ((openedPage === "classes") && !isMobile && item.type === "summary") {
                        return null;
                    }
                    return (
                        <div key={index}>
                            <DynamicTag 
                            tag={item.tag}
                            link={item.link}>
                                { item.content && item.content.map((contentItem, contentIndex) => (
                                    <DynamicTag 
                                    key={contentIndex} 
                                    tag={contentItem.tag} 
                                    link={contentItem.link}
                                    className={contentItem.class}>
                                        {contentItem.text}
                                    </DynamicTag>
                                ))}
                            </ DynamicTag>
                        </div>
                    );
                })}
                </div>
            </div>
        </main>
        </div>
        </div>
    );
}

export default Main;
