import {  
    configureStore, 
    createAsyncThunk, 
    createSelector,
    createSlice 
    } from '@reduxjs/toolkit';

const localFlag = false;
// const localFlag = true;

export const loadPageContent = createAsyncThunk('pages/load', async () => {
  if (localFlag) {
    const response = await fetch('/content.json'); 
    const data = await response.json();
    const transformedData = data.pages.map(entry => ({
      page: entry.title,
      content: entry.content
    }));
    return transformedData;
  }
  else {

    const response = await fetch(
      'https://mupyvgrfdtpa5cjoqoaynsiuqe0jicrj.lambda-url.us-east-1.on.aws',
      {
        method: 'GET',
        mode: 'cors'      
      }
    )
    
    const data = await response.json();
  
    // Recursive function to transform the data
    const transformData = (item) => {
      if (item.S) return item.S;
      if (item.L) return item.L.map(transformData);
      if (item.M) {
        const transformedItem = {};
        for (const key in item.M) {
          transformedItem[key] = transformData(item.M[key]);
        }
        return transformedItem;
      }
      return item;
    };
  
    // Transform the data to match the expected structure
    const transformedData = data.map(entry => ({
      page: entry.page.S,
      content: transformData(entry.content)
    }));
    return transformedData;
  }
  
  
  
  
});



// create a slice for the nav menu state
const isExpandedSlice = createSlice({
    name: 'isExpanded',
    initialState: false, // nav menu is initially open
    reducers: {
      toggleIsExpanded(state) {
        return !state; // toggle the state
      },
    },
});


const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    pages: [
      {
        page: "Home",
        content: [
          { type: "hero header", tag: "h1", class: "hero header", text: "Welcome to Prometheus Studios" },
        ],
      },
    ],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadPageContent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadPageContent.fulfilled, (state, action) => {
        state.pages = action.payload;
        state.loading = false;
      })
      .addCase(loadPageContent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// create a slice for which page is selected
const openedPageSlice = createSlice({
    name: 'openedPage',
    initialState: 'Home',
    reducers: {
      selectPage(state, action) {
        return action.payload;
      },
    },
  });

const pageNameFormatter = (pageName) => {
  const exceptions = ['of', 'and'];
  let cleanName = pageName.split('-');

  return cleanName.map(element => {
    let formattedElement = element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
    if (exceptions.includes(formattedElement.toLowerCase())) {
      formattedElement = formattedElement.toLowerCase();
    }
    return formattedElement;
  }).join(' ');
}

// Selector to get the content of the currently opened page
export const selectOpenedPageContent = createSelector(
    // First, we define input selectors. These are functions that extract the relevant parts of the state.
    state => state.pages,
    state => state.openedPage,

    // Then, we define a result function that takes the values returned by the input selectors and returns the result we want.
    (pages, openedPage) => pages.pages.find(page => page.page === pageNameFormatter(openedPage))
  );

  function checkIsMobile() {
    return /Mobi|Android/i.test(window.navigator.userAgent);
}

  const deviceSlice = createSlice({
    name: 'device',
    initialState: {
        isMobile: checkIsMobile(),
    },
    reducers: {
        setIsMobile: (state) => {
            state.isMobile = checkIsMobile();
        },
    },
});


let store = configureStore({
  reducer: {
    pages: pagesSlice.reducer,
    openedPage: openedPageSlice.reducer,
    isExpanded: isExpandedSlice.reducer,
    device: deviceSlice.reducer,
  },
});

export const { setIsMobile } = deviceSlice.actions;
export const { selectPage } = openedPageSlice.actions;
export const { toggleIsExpanded } = isExpandedSlice.actions;

export default store;