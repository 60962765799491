import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

function Top() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showLogo, setShowLogo] = useState(false);
    const pageName = useSelector(state => state.openedPage);
    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Reset scroll position when pageName changes
        setScrollPosition(0);
        window.scrollTo(0, 0);
    }, [pageName]);

    useEffect(() => {
        if (scrollPosition > 8 * rem) { // todo: calculate this instead of fudging it
            setShowLogo(true);
        } else {
            setShowLogo(false);
        }
    }, [scrollPosition, rem]);

    function turnHyphensIntoTitle(string) {
        // add off and and to exceptions
        const exceptions = ['of', 'and'];
        let cleanName = string.split('-');
        
        return cleanName.map(element => {
            let formattedElement = element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
            if (exceptions.includes(formattedElement.toLowerCase())) {
                formattedElement = formattedElement.toLowerCase();
            }
            return formattedElement;
        }).join(' ');
    }


    const isHomePage = pageName === 'Home';

    return (
        <div>
            <div className="line-wrapper">
                <div className="black-line">
                    <div id="logo" className={`logo-container ${showLogo ? 'show-logo' : ''}`}>
                        <h2>{isHomePage ? 'Prometheus Studios' : turnHyphensIntoTitle(pageName)}</h2>
                    </div>
                </div>
                <div className="white-line"></div>
            </div>
        </div>
    );
}

export default Top;