import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toggleIsExpanded } from './store';


function Hamburger() {
    const dispatch = useDispatch();

    const isExpanded = useSelector(state => state.isExpanded);

    const toggleExpanded = () => {
        dispatch(toggleIsExpanded());
    };
    
    return (
        <div className="nav">
                <div 
                className={`hamburger-menu ${isExpanded ? 'expanded' : ''}`} 
                onClick={toggleExpanded}>
                    <div id="top-bun"></div>
                    <div id="patty"></div>
                    <div></div>
                </div>
            </div>
    );
}

export default Hamburger;