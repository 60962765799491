// src/Loading.js
import React, { useEffect } from 'react';
import './Loading.css'; // Optional: Add custom styles for the loading component

const Loading = () => {

    return (
        <div className="loading-container">
            <img className="swing-image" src="./logo2160.png" alt="Prometheus Studios logo" />
        </div>
    );
};

export default Loading;