import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { selectPage, toggleIsExpanded } from './store';

function Nav() {
    const dispatch = useDispatch();

    const isExpanded = useSelector(state => state.isExpanded);
    const { pageName } = useParams();

    const navLinks = [
        { name: 'Home', path: '/' },
        { name: 'About', path: '/about' },
        { name: 'Classes', path: '/classes' },
        { name: 'FAQ', path: '/faq' },
        { name: 'Testimonials', path: '/testimonials'},
        { name: 'Contact', path: '/contact'},
        { name: 'Tickets', path: '/tickets'},
        { name: 'Privacy Policy', path: '/privacy-policy'},
        { name: 'Terms of Service', path: '/terms-of-service'},
    ]

    const currentPage = pageName ? pageName : 'Home';

    useEffect(() => {
        dispatch(selectPage(currentPage));
    }, [currentPage, dispatch]);

    const handleClick = (pageName) => {
        dispatch(selectPage(pageName));
        dispatch(toggleIsExpanded());
        window.scrollTo(0, 0);
      };

    return (
        <div 
        className={`nav-menu ${isExpanded ? 'show' : ''}`}
        >
            {navLinks.map((link, index) => (
                <Link key={index} to={link.path} onClick={() => handleClick(link.name)}>
                    {link.name}
                </Link>
            ))}
        </div>
    );
}

export default Nav;